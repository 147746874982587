import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import EventsPage from './Pages/EventsPage';
import LoginPage from './Pages/LoginPage';
import BookingsPage from './Pages/BookingsPage';
import DrinksPage from './Pages/DrinksPage';
import DeniedPage from './Pages/DeniedPage';
import SuccessPage from './Pages/SuccessPage';
import ErrorPage from './Pages/ErrorPage';
import JankFestPage from './Pages/Events/JankFestPage';
import CrewBattlePage from './Pages/Events/CrewBattlePage';
import SecretSantaPage from './Pages/Events/SecretSantaPage';
import CrewOrganizerPage from './Pages/Tools/CrewOrganizerPage';
import ZenithPage from './Pages/Tools/ZenithPage';
import Header from './Components/Header';
import Footer from './Components/Footer';

const App = () => {
  return (
    <div className="flex flex-grow flex-col min-h-screen">
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/events" element={<EventsPage />} />
        <Route path="/auth/callback" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/denied" element={<DeniedPage />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/bookings" element={<BookingsPage />} />
        <Route path="/drinks" element={<DrinksPage />} />
        <Route path="/profile" element={<HomePage />} />
        <Route path="/events/jankfest" element={<JankFestPage />} />
        <Route path="/events/crew-battle" element={<CrewBattlePage />} />
        <Route path="/events/santa" element={<SecretSantaPage />} />
        <Route path="/tools/to" element={<ZenithPage />} />
        <Route path="/tools/to2" element={<CrewOrganizerPage />} />
        <Route path="/404" element={<ErrorPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
