import React, { useState, useRef, useEffect } from 'react';
import { DxVersion, Categories, GameVersion } from '../../Data/constants.js';
import { FaUser, FaWindowClose, FaLock } from "react-icons/fa";
import { getGradeDiv, getClampedAccuracy, getLabelById, getDifficultyBgColor } from '../../Helpers/Helpers.js';
import { TiCancel } from "react-icons/ti";
import { FiRefreshCw } from "react-icons/fi";

const TournamentSongCard = ({ index, song, animate, p1User, p2User, p3User, p4User, onScoresChange, onClose, onBan, onReplace, onLock, isControlPanel, previewLocked }) => {
    const [expanded, setExpanded] = useState(false);
    const [p1Acc, setP1Acc] = useState(0);
    const [p2Acc, setP2Acc] = useState(0);
    const [p3Acc, setP3Acc] = useState(0);
    const [p4Acc, setP4Acc] = useState(0);
    const [p1Text, setP1Text] = useState("");
    const [p2Text, setP2Text] = useState("");
    const [p3Text, setP3Text] = useState("");
    const [p4Text, setP4Text] = useState("");
    const [winner, setWinner] = useState(0);
    const [isHovered, setIsHovered] = useState(false);

    const p1AccInputRef = useRef(null);
    const p2AccInputRef = useRef(null);
    const p3AccInputRef = useRef(null);
    const p4AccInputRef = useRef(null);

    const difficulty = parseFloat(song["Bud"]);

    useEffect(() => {
        let diff = false;
        let acc1 = p1Acc;
        let acc2 = p2Acc;
        let acc3 = p3Acc;
        let acc4 = p4Acc;

        console.log(song.scoreP1);

        if (song.scoreP1 && p1Acc != song.scoreP1){
            acc1 = updateP1Acc(song.scoreP1, true);
            setExpanded(true);
            diff = true;
        }
        if (song.scoreP2 && p2Text != song.scoreP2){
            acc2 = updateP2Acc(song.scoreP2, true);
            setExpanded(true);
            diff = true;
        }
        if (song.scoreP3 && p3Text != song.scoreP3){
            acc3 = updateP3Acc(song.scoreP3, true);
            setExpanded(true);
            diff = true;
        }
        if (song.scoreP4 && p4Text != song.scoreP4){
            acc4 = updateP4Acc(song.scoreP4, true);
            setExpanded(true);
            diff = true;
        }

        if (diff){
            updateWinner(acc1, acc2, acc3, acc4, true);
        }
    }, [ song.scoreP1, song.scoreP2, song.scoreP3, song.scoreP4 ]);

    const updateWinner = (acc1, acc2, acc3, acc4, doNotInvokeWinner = false) => {
        var accs = [
            {id: 1, acc: acc1},
            {id: 2, acc: acc2},
            {id: 3, acc: acc3},
            {id: 4, acc: acc4},
        ];
        
        const scoring = {
            1: 5,
            2: 3,
            3: 2,
            4: 1
        };
        
        let scores = [];
        let currentScore = 0;
        let lastAcc = null;
        accs.sort((a, b) => b.acc > a.acc);
        
        for (let i = 0; i < accs.length; i++) {
            const acc = accs[i];
            if (acc.acc !== lastAcc) {
                currentScore = scoring[i + 1] || 0;
                lastAcc = acc.acc;

                if (acc.acc <= 0){
                    currentScore = 0;
                }
            }
            scores.push({id: acc.id, score: currentScore});
        }

        song.scoreP1 = acc1;
        song.scoreP2 = acc2;
        song.scoreP3 = acc3;
        song.scoreP4 = acc4;
        
        if (acc1 === acc2 && acc2 === acc3 && acc3 == acc4) {
            setWinner(0);
        } else if (acc1 > acc2 && acc1 > acc3 && acc1 > acc4) {
            setWinner(1);
        } else if (acc2 > acc1 && acc2 > acc3 && acc2 > acc4) {
            setWinner(2);
        } else if (acc3 > acc1 && acc3 > acc2 && acc3 > acc4) {
            setWinner(3);
        } else if (acc4 > acc1 && acc4 > acc2 && acc4 > acc3) {
            setWinner(4);
        } else {
            setWinner(0);
        }

        if (!doNotInvokeWinner)
            onScoresChange(scores, song, acc1, acc2, acc3, acc4);
    };

    const handleP1AccChange = (e) => {
        setP1Text(e.target.value);
    };

    const handleP2AccChange = (e) => {
        setP2Text(e.target.value);
    };

    const handleP3AccChange = (e) => {
        setP3Text(e.target.value);
    };

    const handleP4AccChange = (e) => {
        setP4Text(e.target.value);
    };

    const handleP1AccKeyPress = (e) => {
        if (e.key === 'Enter') {
            updateP1Acc(e.target.value);
            if (p2AccInputRef.current) {
                p2AccInputRef.current.focus();
            }
        }
    };

    const handleP2AccKeyPress = (e) => {
        if (e.key === 'Enter') {
            updateP2Acc(e.target.value);
            if (p3AccInputRef.current) {
                p3AccInputRef.current.focus();
            }
        }
    };

    const handleP3AccKeyPress = (e) => {
        if (e.key === 'Enter') {
            updateP3Acc(e.target.value);
            if (p4AccInputRef.current) {
                p4AccInputRef.current.focus();
            }
        }
    };

    const handleP4AccKeyPress = (e) => {
        if (e.key === 'Enter') {
            updateP4Acc(e.target.value);
            if (p1AccInputRef.current) {
                p1AccInputRef.current.focus();
            }
        }
    };

    const handleP1AccBlur = (e) => {
        updateP1Acc(e.target.value);
    };

    const handleP2AccBlur = (e) => {
        updateP2Acc(e.target.value);
    };

    const handleP3AccBlur = (e) => {
        updateP3Acc(e.target.value);
    };

    const handleP4AccBlur = (e) => {
        updateP4Acc(e.target.value);
    };

    const updateP1Acc = (value = null, doNotUpdate = false) => {
        const acc = Number(getClampedAccuracy(value ?? p1Text));
        const text = `${acc}%`;
        setP1Acc(acc);
        setP1Text(text);

        if (!isControlPanel && !doNotUpdate)
            updateWinner(acc, p2Acc, p3Acc, p4Acc);

        return acc;
    };

    const updateP2Acc = (value = null, doNotUpdate = false) => {
        const acc = Number(getClampedAccuracy(value ?? p2Text));
        const text = `${acc}%`;
        setP2Acc(acc);
        setP2Text(text);

        if (!isControlPanel && !doNotUpdate)
            updateWinner(p1Acc, acc, p3Acc, p4Acc);

        return acc;
    };

    const updateP3Acc = (value = null, doNotUpdate = false) => {
        const acc = Number(getClampedAccuracy(value ?? p3Text));
        const text = `${acc}%`;
        setP3Acc(acc);
        setP3Text(text);

        if (!isControlPanel && !doNotUpdate)
            updateWinner(p1Acc, p2Acc, acc, p4Acc);
        
        return acc;
    };

    const updateP4Acc = (value = null, doNotUpdate = false) => {
        const acc = Number(getClampedAccuracy(value ?? p4Text));
        const text = `${acc}%`;
        setP4Acc(acc);
        setP4Text(text);

        if (!isControlPanel && !doNotUpdate)
            updateWinner(p1Acc, p2Acc, p3Acc, acc);

        return acc;
    };

    let winnerAttributes = "";
    let winnerAttributesB = "";
    let bgColor = getDifficultyBgColor(song["Difficulty Id"]);
    if ((expanded || isControlPanel) && p1Text !== "" && p2Text !== "") {
        switch(winner){
            case 0:
                winnerAttributes = "ring-8 ring-zinc-200 rounded";
                winnerAttributesB = "bg-zinc-50";
                break;
            case 1:
                winnerAttributes = "ring-8 ring-sky-500 rounded";
                winnerAttributesB = "bg-sky-100";
                break;
            case 2:
                winnerAttributes = "ring-8 ring-rose-500 rounded";
                winnerAttributesB = "bg-rose-100";
                break;
            case 3:
                winnerAttributes = "ring-8 ring-yellow-500 rounded";
                winnerAttributesB = "bg-yellow-100";
                break;
            case 4:
                winnerAttributes = "ring-8 ring-emerald-500 rounded";
                winnerAttributesB = "bg-emerald-100";
                break;
        }
    }

    return (
        <div
            className={`font-shippori relative w-full ${previewLocked && "select-none"} ${animate && 'animate-[spin_0.4s_ease-out]'} ${winnerAttributes} ${winnerAttributesB}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{ pointerEvents: previewLocked ? 'none' : 'auto' }}
        >
            {
                isHovered && 
                <div className="absolute top-8 right-1 z-10 flex flex-col gap-0.5 items-end">
                    <button
                        className="text-white text-xs bg-black px-1 py-0.5 hover:text-red-500 flex gap-1 items-center rounded"
                        onClick={() => onClose(song)}
                    >
                        Remove <FaWindowClose />
                    </button>
                    <button
                        className="text-white text-xs bg-black px-1 py-0.5 hover:text-red-500 flex gap-1 items-center rounded"
                        onClick={() => {
                            song.banned = !song.banned;
                            onBan(song)
                        }}
                    >
                        {song.banned ? "Unban" : "Ban"} <TiCancel />
                    </button>
                    <button
                        className="text-white text-xs bg-black px-1 py-0.5 hover:text-red-500 flex gap-1 items-center rounded"
                        onClick={() => {
                            song.userLocked = true;
                            onLock(song)
                        }}
                    >
                        Lock <FaLock />
                    </button>
                    <button
                        className="text-white text-xs bg-black px-1 py-0.5 hover:text-red-500 flex gap-1 items-center rounded"
                        onClick={() => {
                            song.replaced = true;
                            onReplace(song)
                        }}
                    >
                        Replace <FiRefreshCw />
                    </button>
                </div>
            }

            <div className={`bg-white w-full rounded-t-lg overflow-hidden ${(expanded || isControlPanel) && 'rounded-lg'} ${song.banned && 'opacity-20'}`}>
                {song.Jacket && <img src={`/songs/${song.Jacket}`} className={`w-full h-32 object-cover ${(song.banned || song.replaced) && "opacity-60"}`} alt={song.Title} />}
                <div className={`${song.banned || song.replaced ? "bg-zinc-200 text-zinc-600" : bgColor} cursor-pointer px-2 py-1.5 flex justify-between songs-center gap-4`} onClick={() => setExpanded(!expanded)}>
                    {song.Title && <h3 className="font-bold text-xs truncate w-full text-left">{song.Title}</h3>}
                    {song["Bud"] && <h3 className="font-bold text-xs">{difficulty.toFixed(1)}</h3>}
                </div>
                <div className={`flex flex-col ${winnerAttributesB}`}>
                    {(expanded || isControlPanel) && (
                        <div className="flex flex-col gap-0.5 py-1">
                            {p1User && <div className="flex gap-1 items-center mx-1">
                                <div className="bg-sky-500 text-white font-bold flex gap-1 px-2 py-0.5 rounded-lg text-2xs text-center items-center whitespace-nowrap">
                                    <FaUser />
                                    {p1User}
                                </div>
                                <input
                                    ref={p1AccInputRef}
                                    type="text"
                                    placeholder="Accuracy"
                                    value={p1Text}
                                    onChange={handleP1AccChange}
                                    onKeyPress={handleP1AccKeyPress}
                                    onBlur={handleP1AccBlur}
                                    className={`px-1 py-0.5 text-sm w-full rounded-lg ${winner === 1 &&'font-bold'} ${p1User ? 'bg-white' : 'bg-sky-500'}`}
                                />
                                {getGradeDiv(p1Acc)}
                            </div>}
                            {p2User && <div className="flex gap-1 items-center mx-1">
                                <div className="bg-rose-500 text-white font-bold flex gap-1 px-2 py-0.5 rounded-lg text-2xs text-center items-center whitespace-nowrap">
                                    <FaUser />
                                    {p2User}
                                </div>
                                <input
                                    ref={p2AccInputRef}
                                    type="text"
                                    placeholder="Accuracy"
                                    value={p2Text}
                                    onChange={handleP2AccChange}
                                    onKeyPress={handleP2AccKeyPress}
                                    onBlur={handleP2AccBlur}
                                    className={`px-1 py-0.5 text-sm w-full rounded-lg ${winner === 2 &&'font-bold'} ${p2User ? 'bg-white' : 'bg-rose-500'}`}
                                />
                                {getGradeDiv(p2Acc)}
                            </div>}
                            {p3User && <div className="flex gap-1 items-center mx-1">
                                <div className="bg-yellow-500 text-white font-bold flex gap-1 px-2 py-0.5 rounded-lg text-2xs text-center items-center whitespace-nowrap">
                                    <FaUser />
                                    {p3User}
                                </div>
                                <input
                                    ref={p3AccInputRef}
                                    type="text"
                                    placeholder="Accuracy"
                                    value={p3Text}
                                    onChange={handleP3AccChange}
                                    onKeyPress={handleP3AccKeyPress}
                                    onBlur={handleP3AccBlur}
                                    className={`px-1 py-0.5 text-sm w-full rounded-lg ${winner === 3 &&'font-bold'} ${p4User ? 'bg-white' : 'bg-yellow-500'}`}
                                />
                                {getGradeDiv(p3Acc)}
                            </div>}
                            {p4User && <div className="flex gap-1 items-center mx-1">
                                <div className="bg-emerald-500 text-white font-bold flex gap-1 px-2 py-0.5 rounded-lg text-2xs text-center items-center whitespace-nowrap">
                                    <FaUser />
                                    {p4User}
                                </div>
                                <input
                                    ref={p4AccInputRef}
                                    type="text"
                                    placeholder="Accuracy"
                                    value={p4Text}
                                    onChange={handleP4AccChange}
                                    onKeyPress={handleP4AccKeyPress}
                                    onBlur={handleP4AccBlur}
                                    className={`px-1 py-0.5 text-sm w-full rounded-lg ${winner === 4 &&'font-bold'} ${p4User ? 'bg-white' : 'bg-emerald-500'}`}
                                />
                                {getGradeDiv(p4Acc)}
                            </div>}

                            {isControlPanel && 
                                <button className="bg-yellow-400 hover:bg-yellow-300 text-xs my-1 py-1 mx-1" onClick={() => updateWinner(p1Acc, p2Acc, p3Acc, p4Acc)}>Submit</button>
                            }
                        </div>
                    )}
                </div>
                <div className="absolute top-0 flex flex-wrap w-full mx-1 my-1 gap-1">
                <p className={`whitespace-nowrap text-black text-center item-center lowercase text-xs bg-white px-1.5 rounded bg-emerald-300`}>{index + 1}</p>
                    {song.userLocked && <p className={`whitespace-nowrap text-black text-center item-center lowercase text-xs bg-black px-1.5 rounded bg-emerald-500`}>Locked</p>}
                    {song.banned && <p className={`whitespace-nowrap text-black text-center item-center lowercase text-xs bg-black px-1.5 rounded bg-rose-500`}>Banned</p>}
                    {song.replaced && <p className={`whitespace-nowrap text-black text-center item-center lowercase text-xs bg-black px-1.5 rounded bg-pink-500`}>Replaced</p>}
                    {song.rerolled && <p className={`whitespace-nowrap text-black text-center item-center lowercase text-xs bg-black px-1.5 rounded bg-rose-500`}>Rerolled</p>}
                    {/* {song.pocketPicked && <p className={`whitespace-nowrap text-black text-center item-center lowercase text-xs bg-black px-1.5 rounded bg-emerald-300`}>Picked</p>} */}
                    <p className={`whitespace-nowrap text-black text-center item-center lowercase text-xs bg-black px-1.5 rounded ${song["Dx Version"] == 1 ? 'bg-cyan-300' : 'bg-yellow-300'}`}>{getLabelById(song["Dx Version"], DxVersion).short_label}</p>
                    <p className={`whitespace-nowrap text-white text-center item-center lowercase text-xs bg-black px-1.5 pb-0.5 rounded`}>{getLabelById(song["Game Version"], GameVersion).label}</p>
                    <p className={`whitespace-nowrap text-white text-center item-center lowercase text-xs bg-black px-1.5 pb-0.5 rounded`}>{getLabelById(song["Category"], Categories).label}</p>
                </div>
            </div>
        </div>
    );
}

export default TournamentSongCard;
