import React, { useEffect, useState } from 'react';
import WeeklyCalendar from './../Components/WeeklyCalendar';
import MonthlyCalendar from './../Components/MonthlyCalendar';
import { useAuth } from './../Auth/AuthContext';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function formatDate(date) {
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
    let day = String(date.getDate()).padStart(2, '0');

    let hours = String(date.getHours()).padStart(2, '0');
    let minutes = String(date.getMinutes()).padStart(2, '0');
    let seconds = String(date.getSeconds()).padStart(2, '0');

    let formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
}

const BookingsPage = () => {
    let today = new Date();
    let currentHour = today.getHours();
    today.setMinutes(0);
    today.setSeconds(0);

    if (currentHour < 11) {
        today.setHours(12);
    } else if (currentHour < 13) {
        today.setHours(14);
    } else if (currentHour < 15) {
        today.setHours(16);
    } else {
        today.setHours(18);
    }
    
    const navigate = useNavigate();
    const requestAbortController = React.useRef(null);
    const { user, membership } = useAuth();
    const isMember = membership >= 1;
    const [bookings, setBookings] = React.useState([]);
    const [selectedDate, setSelectedDate] = useState(today);
    const [selectedGame, setSelectedGame] = useState(1);
    const [isBookableType, setIsBookableType] = React.useState(0);
    const [isGameAvailable, setIsGameAvailable] = React.useState([false, false, true]);
    const [popupState, setPopupState] = useState(0);
    const games = [
        {id: 1, name: 'maimai'},
        // {id: 2, name: 'ongeki'},
        {id: 3, name: 'ongeki / fill'},
    ];

    useEffect(() => {
        if (!isMember) {
            navigate('/404');
        }
    }, [membership, navigate]);

    const handleBookingButtonClick = async (e) => {

        if ((isBookableType !== 1 && isBookableType !== 3) || membership < 2){
            return;
        }

        setPopupState(1);
        setBookings([]);
        e.preventDefault();
        
        let now = new Date();
        const notes = `Booked via kumakult.com @ ${now.toLocaleDateString('default', { weekday: 'long', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' })}`;
        const scriptURL = 'https://script.google.com/macros/s/AKfycbwO_NbIEl1nQGOCE5geTjR2YPq-CNdtoDIj7P6paApFGWrU4ebZWmA4akCm5COGB7Bd/exec'; // Replace with your actual script URL
        const formData = new FormData();
        const serializedDate = formatDate(selectedDate);
        const duration = isBookableType === 3 ? 1 : 2;
        formData.append('date', serializedDate);
        formData.append('duration', duration);
        formData.append('type', 0);
        formData.append('game', selectedGame.toString());
        formData.append('booker', user.username);
        formData.append('bookerId', user.id.toString());
        formData.append('notes', notes);

        try {
            const response = await fetch(scriptURL, {
                method: 'POST',
                body: formData
            });
            
            if (response.ok) {
                setPopupState(2);
            } else {
                console.error('Booking submission failed:', response.statusText);
                setPopupState(3);
            }
        } catch (error) {
            console.error('Error submitting booking:', error);
            setPopupState(3);
        }
    };

    const handlePopupClose = async () => {
        // await fetchDataAndHandleChange();
        // setPopupState(0);
        window.location.reload();
      };

    const fetchDates = async (date) => {
        const controller = new AbortController();
    
        try {
            const response = await axios.get(
                'https://sheets.googleapis.com/v4/spreadsheets/16et5pim2KikbJZyQLleq1MS5yZ_PdRAOxbT5dliDEWg/values/database!A2:G?key=AIzaSyASPeN82JnlKM49W6pJPTvxLisjc8b9X3w'
            );
    
            let bookings = [];
            response.data.values.forEach(row => {
                let [start, duration, type, game, booker, id, notes] = row;
                let date = new Date(start);
                let parsedType = parseInt(type);
        
                if (parsedType != 6){
                    bookings.push({date: date, duration: parseInt(duration), type: parsedType, game: parseInt(game), booker: booker, id: id, notes: notes});
                }
            });
    
            return bookings;
        }   catch (error) {
            console.error('Error fetching data from Google Sheets:', error);
            return [];
        }
    
        requestAbortController.current = controller;
      };

      const fetchDataAndHandleChange = async () => {
        let bookings = await fetchDates(today);
        setBookings(bookings);
      };

      React.useEffect(() => {
        if (!isMember)
            return () => requestAbortController.current?.abort();
    
        fetchDataAndHandleChange();
    
        return () => requestAbortController.current?.abort();
      }, []);

    if (!user){
        return (
            <main className="font-shippori bg-white">
                <div className="max-w-6xl mx-auto py-16 px-4 justify-center items-center flex-col flex min-h-[calc(80vh)]">
                    <div className="animate-pulse">
                        <h1 className="text-2xl text-center mb-1 animate-bounce">x - x</h1>
                        <h2 className="text-xs text-center font-bold mb-16 text-gray-700">log in to view page</h2>
                    </div>
                    <Link to="/login" className="text-black flex items-center transition-color text-white duration-200 bg-[#5865f2] hover:bg-[#727be8] w-48 h-20 justify-center">
                        Log in
                    </Link>
                </div>
            </main>
        );
    }

    let bookingButtonMessage = 'Unavailable';
    let bookingButtonColor = 'bg-zinc-300';

    switch(isBookableType){
        case 1: 
            if (membership >= 2){
                bookingButtonMessage = `Book ${selectedDate.toLocaleDateString('default', { weekday: 'long', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' })}`;  
                bookingButtonColor = 'bg-cyan-300';
            }
            else {
                bookingButtonMessage = 'Only Priority Members may book.'; 
                bookingButtonColor = 'bg-zinc-300';
            }
            break;
        case 2: 
            bookingButtonMessage = 'No Booking Required'; 
            bookingButtonColor = 'bg-zinc-300';
            break;
        case 3: 
            if (membership >= 2){
                bookingButtonMessage = `Join on ${selectedDate.toLocaleDateString('default', { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' })} (Ask the booker first!)`;  
                bookingButtonColor = 'bg-yellow-300';
            }
            else {
                bookingButtonMessage = 'Only Priority Members may book.'; 
                bookingButtonColor = 'bg-zinc-300';
            }
            break;
        case 4:
            bookingButtonMessage = 'Walk-ins Available'; 
            bookingButtonColor = 'bg-zinc-300';
            break;
        case 5:
            bookingButtonMessage = 'Cannot book >2 weeks in advance'; 
            bookingButtonColor = 'bg-zinc-300';
            break;
        case 6:
            bookingButtonMessage = 'Cannot have >2 bookings per week'; 
            bookingButtonColor = 'bg-zinc-300';
            break;
        default:
            bookingButtonMessage = 'Unavailable'; 
            bookingButtonColor = 'bg-zinc-300';
            break;
    }

    return (
        <main className="max-w-6xl mx-auto py-12 px-4 font-shippori flex flex-col min-h-[calc(85vh)] justify-between">
            <div className="mb-6">
                <h1 className="text-black text-4xl font-bold">Bookings</h1>
                <h2 className="text-black text-sm">for members only</h2>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 w-full justify-center mb-2">
                <MonthlyCalendar 
                    selectedDate={selectedDate} 
                    setSelectedDate={setSelectedDate} 
                    bookings={bookings} 
                    selectedGame={selectedGame}
                    user={user}
                />
                <WeeklyCalendar
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    setIsBookableType={setIsBookableType}
                    setIsGameAvailable={setIsGameAvailable}
                    bookings={bookings}
                    selectedGame={selectedGame}
                    user={user}
                />
                <div className="md:flex items-center justify-between gap-4">
                <div className="flex flex-col gap-0.5">
                    <h3 className="text-xs font-bold mb-1">Select a game to book</h3>
                    {
                        games.map((game, index) => (
                            <div className="flex items-center" key={game.id}>
                                <input
                                    type="radio"
                                    id={game.id}
                                    name="selected_game"
                                    className="focus:ring-cyan-500 h-4 w-4 text-cyan-600 border-gray-300 checked:text-cyan-300"
                                    onChange={() => setSelectedGame(game.id)}
                                    checked={selectedGame === game.id}
                                />
                                <div htmlFor={game.name} className="flex gap-2">
                                    <label className="ml-2 block text-md text-gray-900">{game.name}</label>
                                    { isGameAvailable[index]
                                        ? <div className="p-1 text-xs font-bold bg-cyan-400 text-white rounded-lg">Available</div>
                                        : <div className="p-1 text-xs font-bold bg-rose-400 text-white rounded-lg">Unavailable</div>
                                    }
                                </div>
                            </div>
                        ))
                    }
                    <h4 className="text-xs font-bold mt-4 mb-1">Legend</h4>
                    <p className="text-xs flex gap-2 text-sm whitespace-nowrap items-center">
                    <div className="bg-green-400 text-xs w-6 h-3 text-center justify-center rounded-full items-center flex gap-1"></div>
                        Open House & Walk-ins - No booking required
                    </p>
                    <p className="text-xs flex gap-2 text-sm whitespace-nowrap items-center">
                    <div className="bg-cyan-300 text-xs w-6 h-3 text-center justify-center rounded-full items-center flex gap-1"></div>
                        Available for booking
                    </p>
                    <p className="text-xs flex gap-2 text-sm whitespace-nowrap items-center">
                        <div className="bg-gradient-to-r bg-yellow-300 from-yellow-300 to-orange-300 text-xs w-6 h-3 text-center justify-center rounded-full items-center flex gap-1"></div>
                        Available for booking, but busy (only shown on monthly calendar)
                    </p>
                    <p className="text-xs flex gap-2 text-sm whitespace-nowrap items-center">
                        <div className="bg-red-400 text-xs w-6 h-3 text-center justify-center rounded-full items-center flex gap-1"></div>
                        Not Available, Full
                    </p>
                    <p className="text-xs flex gap-2 text-sm whitespace-nowrap items-center">
                        <div className="bg-white border-2 border-black text-xs w-6 h-3 text-center justify-center rounded-full items-center flex gap-1"></div>
                        Not Available
                    </p>
                </div>
            </div>
            <div className="flex flex-col gap-1">
            <button className={`px-8 py-4 text-lg transition duration-200 ${bookingButtonColor}`} onClick={handleBookingButtonClick}>{bookingButtonMessage}</button>
                <p className="text-center text-xs">Contact <b>wubbo</b> directly to change an existing booking.</p>
            </div>
        </div>
        { popupState === 1 && 
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-80 z-20">
                <div className="bg-white px-20 py-12 text-center">
                    <h3 className="text-2xl text-black">Booking...</h3>
                    <p className="text-xs font-bold mb-12 text-zinc-700">Please wait...</p>
                    <p className="text-zinc-700 text-sm text-center font-bold animate-bounce">@ - @</p>
                </div>
            </div>
        }
        { popupState === 2 && 
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-80 z-20">
                <div className="bg-white px-20 py-12 text-center">
                    <h3 className="text-2xl text-black">Booked!</h3>
                    <p className="text-xs font-bold mb-12 text-zinc-700">See you soon!</p>
                    <button className="bg-cyan-500 text-white px-4 py-2 rounded hover:bg-cyan-600" onClick={handlePopupClose}>Refresh Page</button>
                </div>
            </div>
        }
        { popupState === 3 && 
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-80 z-20">
                <div className="bg-white px-20 py-12 text-center">
                    <h3 className="text-2xl text-black">Booking failed.</h3>
                    <p className="text-xs font-bold mb-12 text-zinc-700">There was an error with your booking. Please contact wubbo to make the booking.</p>
                    <button className="bg-cyan-500 text-white px-4 py-2 rounded hover:bg-cyan-600" onClick={handlePopupClose}>Refresh Page</button>
                </div>
            </div>
        }
    </main>
    );
};

export default BookingsPage;
