import React, { useState, useEffect, useRef } from 'react';
import Mascot from '../../Components/Mascot';
import CrewAbilityCard from '../../Components/Tournament/CrewAbilityCard';
import CrewSongCard from '../../Components/Tournament/CrewSongCard';
import SongPickPanel from '../../Components/Tournament/SongPickPanel';
import PlayerCard from '../../Components/Tournament/PlayerCard';
import Charts from '../../Data/Charts.json';
import CrewAbilities from '../../Data/CrewAbilities.json';
import DropdownInputField from '../../Components/Tournament/DropdownInputField';
import { FaMicrophone } from "react-icons/fa";

const formatDifficulty = (difficulty) => {
    const integerPart = Math.floor(difficulty);
    const decimalPart = difficulty - integerPart;
  
    return decimalPart >= 0.6 ? `${integerPart}+` : Math.floor(difficulty);
};

const CrewOrganizerPage = () => {
    const [randomizedCharts, setRandomizedCharts] = useState([]);
    const [abilities, setAbilities] = useState(CrewAbilities);
    const [firstAbilityUsed, setFirstAbilityUsed] = useState(false);
    const [p1User, setP1User] = useState("");
    const [p2User, setP2User] = useState("");
    const [p3User, setP3User] = useState("");
    const [p4User, setP4User] = useState("");
    const [p1Commentator, setP1Commentator] = useState("");
    const [p2Commentator, setP2Commentator] = useState("");
    const [p3Commentator, setP3Commentator] = useState("");
    const [roundLabel, setRoundLabel] = useState("");
    const [p1Score, setP1Score] = useState(0);
    const [p2Score, setP2Score] = useState(0);
    const [p3Score, setP3Score] = useState(0);
    const [p4Score, setP4Score] = useState(0);
    const [isLockedIn, setIsLockedIn] = useState(false);
    const [minDiff, setMinDiff] = useState(12.4);
    const [maxDiff, setMaxDiff] = useState(14.6);
    const [totalCardsToDraw, setTotalCardsToDraw] = useState(5);
    const [visiblePickPanel, setVisiblePickPanel] = useState(false);
    const [previewLocked, setPreviewLocked] = useState(false);
    const [scoringMode, setScoringMode] = useState(0);
    const [versionMode, setVersionMode] = useState(0);
    const [lockedMode, setLockedMode] = useState(0);
    const [scores, setScores] = useState(JSON.parse(localStorage.getItem('scores')) || []);
    const [matches, setMatches] = useState({});
    const [players, setPlayers] = useState(["jason", "jason2", "bob"]);
    const [teams, setTeams] = useState(["jason", "jason2", "bob"]);

    const p1commentatorRef = useRef(null);
    const p2commentatorRef = useRef(null);
    const p3commentatorRef = useRef(null);
    const p1UserRef = useRef(null);
    const p2UserRef = useRef(null);
    const p3UserRef = useRef(null);
    const p4UserRef = useRef(null);
    const roundTextRef = useRef(null);

    const handleLockIn = (locked) => {
      setIsLockedIn(locked);

      if (locked){
        setRandomizedCharts(prevCharts => {
          const updatedCharts = prevCharts.filter(chart => (/*!chart.banned &&*/ !chart.replaced));
          return [...updatedCharts];
      });
      }
    }

    const clearScore = (id) => {
      const filteredScores = scores.filter(score => score.id !== id);
      setScores(filteredScores);
    }

    const clearScores = () => {
      setScores([]);
    }

    const insertOrUpdateScore = (newScoreA, newScoreB, newScoreC, newScoreD) => {
        const existingScoreIndexA = newScoreA && scores.findIndex(score => score.id === newScoreA.id);
        const existingScoreIndexB = newScoreB && scores.findIndex(score => score.id === newScoreB.id);
        const existingScoreIndexC = newScoreC && scores.findIndex(score => score.id === newScoreC.id);
        const existingScoreIndexD = newScoreD && scores.findIndex(score => score.id === newScoreD.id);
        const updatedScores = [...scores];

        if (existingScoreIndexA !== -1 && newScoreA != null) {
            updatedScores[existingScoreIndexA] = newScoreA;
        } else if (newScoreA !==  null) {
            updatedScores.push(newScoreA);
        }

        if (existingScoreIndexB !== -1  && newScoreB != null) {
            updatedScores[existingScoreIndexB] = newScoreB;
        } else if (newScoreB !==  null) {
            updatedScores.push(newScoreB);
        }

        if (existingScoreIndexC !== -1  && newScoreC != null) {
            updatedScores[existingScoreIndexC] = newScoreC;
        } else if (newScoreC !==  null) {
            updatedScores.push(newScoreC);
        }

        if (existingScoreIndexD !== -1  && newScoreD != null) {
            updatedScores[existingScoreIndexD] = newScoreD;
        } else if (newScoreD !==  null) {
            updatedScores.push(newScoreD);
        }

        setScores(updatedScores);
    };


    const handleScoresChange = (scores = null, song = null, p1Acc = 0, p2Acc = 0, p3Acc = 0, p4Acc = 0) => {
        let id = "";

        if (song && scores){
          let scoreA = null;
          let scoreB = null;
          let scoreC = null;
          let scoreD = null;

          id = `${song.Id}-${song["Difficulty Id"]}`;
          const p1Id = `${id}-${p1User}`;
          const p2Id = `${id}-${p2User}`;
          const p3Id = `${id}-${p3User}`;
          const p4Id = `${id}-${p4User}`;

          if (p1User !== ""){
            scoreA = { id: p1Id, user: p1User, round: 0, isQualifier: false, acc: p1Acc, difficulty: song["Bud+"], jacket: song.Jacket, title: song.Title, difficultyId: song["Difficulty Id"] };
          }
          if (p2User !== ""){
              scoreB = { id: p2Id, user: p2User, round: 0, isQualifier: false, acc: p2Acc, difficulty: song["Bud+"], jacket: song.Jacket, title: song.Title, difficultyId: song["Difficulty Id"]};
          }
          if (p3User !== ""){
              scoreC = { id: p3Id, user: p3User, round: 0, isQualifier: false, acc: p3Acc, difficulty: song["Bud+"], jacket: song.Jacket, title: song.Title, difficultyId: song["Difficulty Id"]};
          }
          if (p4User !== ""){
              scoreD = { id: p4Id, user: p4User, round: 0, isQualifier: false, acc: p4Acc, difficulty: song["Bud+"], jacket: song.Jacket, title: song.Title, difficultyId: song["Difficulty Id"]};
          }

          insertOrUpdateScore(scoreA, scoreB, scoreC, scoreD);

          setMatches(prev => ({
              ...prev,
              [id]: {scores: scores, p1Acc: p1Acc, p2Acc: p2Acc, p3Acc: p3Acc, p4Acc: p4Acc}
          }));
        }

        let p1Wins = 0;
        let p2Wins = 0;
        let p3Wins = 0;
        let p4Wins = 0;
        let totalP1Acc = 0;
        let totalP2Acc = 0;
        let totalP3Acc = 0;
        let totalP4Acc = 0;

        Object.keys(matches).forEach(key => {
          if (key !== id){
            totalP1Acc += matches[key].p1Acc;
            totalP2Acc += matches[key].p2Acc;
            totalP3Acc += matches[key].p3Acc;
            totalP4Acc += matches[key].p4Acc;

            matches[key].scores.forEach(score => {
                switch(score.id){
                    case 1:
                        p1Wins += scoringMode == 0 ? (score.score == 5 ? 1 : 0) : score.score;
                        break;
                    case 2:
                        p2Wins += scoringMode == 0 ? (score.score == 5 ? 1 : 0) : score.score;
                        break;
                    case 3:
                        p3Wins += scoringMode == 0 ? (score.score == 5 ? 1 : 0) : score.score;
                        break;
                    case 4:
                        p4Wins += scoringMode == 0 ? (score.score == 5 ? 1 : 0) : score.score;
                        break;
                }
            })
          }
        });

        if (scores)
        {
          scores.forEach(score => {
            switch(score.id){
                case 1:
                    p1Wins += scoringMode == 0 ? (score.score == 5 ? 1 : 0) : score.score;
                    break;
                case 2:
                    p2Wins += scoringMode == 0 ? (score.score == 5 ? 1 : 0) : score.score;
                    break;
                case 3:
                    p3Wins += scoringMode == 0 ? (score.score == 5 ? 1 : 0) : score.score;
                    break;
                case 4:
                    p4Wins += scoringMode == 0 ? (score.score == 5 ? 1 : 0) : score.score;
                    break;
            }
          })
        }

        totalP1Acc += p1Acc;
        totalP2Acc += p2Acc;
        totalP3Acc += p3Acc;
        totalP4Acc += p4Acc;

        switch(scoringMode){
          case 1:
            setP1Score(totalP1Acc.toFixed(2) + "%");
            setP2Score(totalP2Acc.toFixed(2) + "%");
            setP3Score(totalP3Acc.toFixed(2) + "%");
            setP4Score(totalP4Acc.toFixed(2) + "%");
            break;
          case 0:
          case 2:
            setP1Score(p1Wins);
            setP2Score(p2Wins);
            setP3Score(p3Wins);
            setP4Score(p4Wins);
            break;
        }
    };

    const onSubmitCommentators = () => {
      setP1Commentator(p1commentatorRef.current.value);
      setP2Commentator(p2commentatorRef.current.value);
      setP3Commentator(p3commentatorRef.current.value);
    }

    const onSubmitPlayers = () => {
      setP1User(p1UserRef.current.value);
      setP2User(p2UserRef.current.value);
      setP3User(p3UserRef.current.value);
      setP4User(p4UserRef.current.value);
    }

    const onPlayersListChange = (e) => {
      let newPlayers = e.target.value.split('\n')
        .map(player => player.split(' - ')[0]);
    
      setPlayers(newPlayers);
    };

    const onTeamsListChange = (e) => {
      let newTeams = e.target.value.split('\n');
      setTeams(newTeams);
    }

    const onCrewClick = (ability) => {
      ability.isUsed = ability.isUsed ? false : true;

      const newAbilities = [... abilities];
      if (!firstAbilityUsed){
        newAbilities[2].isUsed = true;
        newAbilities[3].isUsed = false;
      }

      setFirstAbilityUsed(true);
      setAbilities(newAbilities);
    }

    const resetAbilities = () => {
      abilities.forEach(x => x.isUsed = false);
      abilities[3].isUsed = true;

      let newAbilities = [... abilities];
      setAbilities(newAbilities);
    }

    const onSongPick = (song) => {
        if (randomizedCharts.length === 0) {
            setRandomizedCharts(prevCharts => [...prevCharts, song]);
            return;
        }

        song.pocketPicked = true;
        // setRandomizedCharts(prevCharts => [song, ...prevCharts]);

        // setRandomizedCharts(prevCharts => prevCharts.slice(1));
        setTimeout(() => {
            setRandomizedCharts(prevCharts => [song, ...prevCharts]);
        }, 1);
    }

    const randomize = (total) => {
        const availableCharts = filteredCharts.filter(chart =>
            !randomizedCharts.some(selectedChart => selectedChart.Id === chart.Id)
        );
    
        if (availableCharts.length === 0) return; // No charts available to draw
    
        let drawnSongs = [];
        for (let i = 0; i < total; i++) {
            if (availableCharts.length === 0) break; // Exit loop if no more available songs
    
            const randomIndex = Math.floor(Math.random() * availableCharts.length);

            let song = availableCharts[randomIndex];
            availableCharts.splice(randomIndex, 1);
    
            song.rerolled = false;
            song.banned = false;
            song.replaced = false;
            song.userLocked = false;
            song.pocketPicked = false;
            song.p1User = "";
            song.p2User = "";
            song.scoreP1 = "";
            song.scoreP2 = "";
            song.scoreP3 = "";
            song.scoreP4 = "";

            drawnSongs.push(song);
        }
    
        setRandomizedCharts(prevCharts => [...prevCharts, ...drawnSongs]);
    };
    

    const removeLast = () => {
        setRandomizedCharts(prevCharts => prevCharts.slice(0, -1));
    };

    const handleRemoveChart = (chartToRemove) => {
      setRandomizedCharts((prevCharts) =>
        prevCharts.filter((chart) => (!(chart.Id === chartToRemove.Id && chart["Difficulty Id"] === chartToRemove["Difficulty Id"])))
      );
    }

    const handleBanChart = (chartToBan) => {
        setRandomizedCharts(prevCharts => {
            const updatedCharts = prevCharts.filter(chart => chart !== chartToBan);
            return [...updatedCharts, chartToBan];
        });
    };

    const handleP1UserChange = (chart) => {
      setRandomizedCharts(randomizedCharts);
    }

    const handleP2UserChange = (chart) => {
      setRandomizedCharts(randomizedCharts);
    }

    const handleLockChart = (chartToLock) => {
        setRandomizedCharts(prevCharts => {
            const updatedCharts = prevCharts.filter(chart => chart !== chartToLock);
            return [chartToLock, ...updatedCharts];
        });
    }

    const handleReroll = (chartToReroll) => {
        const diff = formatDifficulty(chartToReroll["Bud+"]);
    
        // Filter out charts that are in randomizedCharts
        let availableCharts = filteredCharts.filter(chart => 
            chart !== chartToReroll &&
            !randomizedCharts.includes(chart) && // Ensure it's not already in randomizedCharts
            formatDifficulty(chart["Bud+"]) === diff &&
            (chart.Power === chartToReroll.Power && chart.Tech === chartToReroll.Tech && chart.Intelligence === chartToReroll.Intelligence)
        );
    
        if (availableCharts.length === 0) {
            // If no charts found, relax the conditions
            availableCharts = filteredCharts.filter(chart => 
                chart !== chartToReroll &&
                !randomizedCharts.includes(chart) && // Ensure it's not already in randomizedCharts
                formatDifficulty(chart["Bud+"]) === diff &&
                (chart.Power === chartToReroll.Power || chart.Tech === chartToReroll.Tech || chart.Intelligence === chartToReroll.Intelligence)
            );
        }
    
        if (availableCharts.length === 0) {
            return;
        }
    
        const randomIndex = Math.floor(Math.random() * availableCharts.length);
        const randomSong = availableCharts[randomIndex];
        randomSong.rerolled = true;
    
        setRandomizedCharts(prevCharts => {
            // Find the index of the item to replace
            const indexToReplace = prevCharts.findIndex(chart => chart === chartToReroll);
    
            // Create a new array with the item replaced
            const updatedCharts = [...prevCharts]; // Create a copy of the array
            if (indexToReplace !== -1) {
                updatedCharts[indexToReplace] = randomSong; // Replace the item at the found index
            } else {
                updatedCharts.push(randomSong); // Optionally, handle the case where the item is not found
            }
    
            return updatedCharts;
        });
    };
    

    const handleReplacechart = (chartToReplace) => {
        // setRandomizedCharts((prevCharts) =>
        //     prevCharts.filter((chart) => (!(chart.Id === chartToReplace.Id && chart["Difficulty Id"] === chartToReplace["Difficulty Id"])))
        // );

        handleBanChart(chartToReplace)

        setVisiblePickPanel(true);
    }

    const clear = () => {
      setMatches({})
      setP1Score(0);
      setP2Score(0);
      setP3Score(0);
      setP4Score(0);
      setIsLockedIn(false);
      setP1User("");
      setP2User("");
      setP3User("");
      setP4User("");
      resetAbilities();
      setFirstAbilityUsed(false);

      setRandomizedCharts([]);
    };

    // Set Filtered Charts
    let filteredCharts = [];
    let version = versionMode == 0 ? 2097152 : 4194304;
    filteredCharts = Charts.filter(song => {
        const diff = Number(song["Bud+"]);
        const ver = Number(song["Game Version"]);
        return diff >= minDiff && diff <= maxDiff && song["Difficulty Id"] <= 16 && ver <= version && song.Locked <= lockedMode && (song.Power || song.Tech || song.Intelligence || song.Stamina || (diff <= 13.4 && (song["Difficulty Id"] == 8 || song["Difficulty Id"] == 16)));
    });

    useEffect(() => {
      localStorage.setItem('scores', JSON.stringify(scores));
    }, [ scores ]);

    return (
        <main className={`bg-transparent flex gap-4 justify-between absolute items-stretch w-full`}>
          <div className={`w-full px-4 pt-6 ${previewLocked && "caret-transparent select-none"}`}>
            <h3 className="mt-4 font-bold text-xl text-zinc-500">Teams</h3>
            <div className={`flex justify-between mt-4 gap-4 h-14 mx-auto`}>
              {p1User && <PlayerCard player={p1User} setPlayer={setP1User} score={p1Score} bgColor="bg-sky-500" previewLocked={previewLocked}/>}
              {p2User && <PlayerCard player={p2User} setPlayer={setP2User} score={p2Score} bgColor="bg-rose-500" previewLocked={previewLocked}/>}
              {p3User && <PlayerCard player={p3User} setPlayer={setP3User} score={p3Score} bgColor="bg-yellow-500" previewLocked={previewLocked}/>}
              {p4User && <PlayerCard player={p4User} setPlayer={setP4User} score={p4Score} bgColor="bg-green-500" previewLocked={previewLocked}/>}
            </div>
            <div className="flex gap-12 justify-between">
              <div className="w-2/3">
                <h3 className="mt-4 font-bold text-xl text-zinc-500">Commentators</h3>
                <div className={`flex gap-4 mt-4 justify-between`}>
                    {p1Commentator && <div className={`flex text-xl px-4 gap-1 items-center bg-white overflow-hidden font-bold ${p1Commentator ? 'border-4 border-black' : 'border-4 border-dashed border-zinc-500'}`}>
                      <input
                          type="text"
                          placeholder=""
                          value={p1Commentator}
                          onChange={(e) => setP1Commentator(e.target.value)}
                          style={{ pointerEvents: previewLocked ? 'none' : 'auto' }}
                          className="pr-2 m-1 text-2xl w-40 bg-white text-black text-center focus:outline-none"
                      />
                      <FaMicrophone />
                    </div>}
                    {p2Commentator && <div className={`flex text-xl px-4 gap-1 items-center bg-white overflow-hidden font-bold ${p2Commentator ? 'border-4 border-black' : 'border-4 border-dashed border-zinc-500'}`}>
                      <input
                          type="text"
                          placeholder=""
                          value={p2Commentator}
                          onChange={(e) => setP2Commentator(e.target.value)}
                          style={{ pointerEvents: previewLocked ? 'none' : 'auto' }}
                          className="pr-2 m-1 text-2xl w-40 bg-white text-black text-center focus:outline-none"
                      />
                      <FaMicrophone />
                    </div>}
                    {p3Commentator && <div className={`flex text-xl px-4 gap-1 items-center bg-white overflow-hidden ${p3Commentator ? 'border-4 border-black' : 'border-4 border-dashed border-zinc-500'}`}>
                      <input
                          type="text"
                          placeholder=""
                          value={p3Commentator}
                          onChange={(e) => setP3Commentator(e.target.value)}
                          style={{ pointerEvents: previewLocked ? 'none' : 'auto' }}
                          className="pr-2 m-1 text-2xl w-40 bg-white text-black font-bold text-center focus:outline-none"
                      />
                      <FaMicrophone />
                    </div>}
                </div> 
              </div>
              <div className="w-1/3">
                <h3 className="mt-4 font-bold text-xl text-zinc-500">Current Round</h3>
                <div className={`flex gap-4 mt-4 justify-between`}>
                    <div className={`flex text-xl px-4 gap-1 items-center bg-transparent overflow-hidden border-white border-2 w-full`}>
                      <input
                          type="text"
                          placeholder=""
                          value={roundLabel}
                          onChange={(e) => setRoundLabel(e.target.value)}
                          style={{ pointerEvents: previewLocked ? 'none' : 'auto' }}
                          className="pr-2 m-1 text-5xl w-full text-black font-bold text-center  bg-transparent focus:outline-none"
                      />
                    </div>
                </div> 
              </div>
            </div>


            <h3 className="font-bold text-xl text-zinc-500">Abilities</h3>
            <div className={`justify-items-center mt-8 grid w-full mx-auto grid-cols-4 gap-4`}>
              {abilities.map((item) => (
                  <CrewAbilityCard key={item.title} item={item} previewLocked={previewLocked}
                  onClick={onCrewClick} showDescription={true}/>
              ))}
            </div>
            <h3 className="font-bold text-xl text-zinc-500">Card Draw</h3>
            <div className={`justify-items-center mt-8 grid max-w-3xl mx-auto ${isLockedIn ? "grid-cols-3 gap-x-4 gap-y-20" : "grid-cols-3 gap-x-4 gap-y-20"}`}>
                {randomizedCharts.map((chart, index) => (
                    <CrewSongCard
                        items={players}
                        key={chart.id}
                        song={chart}
                        animate={false}
                        p1User={p1User}
                        p2User={p2User}
                        p3User={p3User}
                        p4User={p4User}
                        onP1UserChange={handleP1UserChange}
                        onP2UserChange={handleP2UserChange}
                        onScoresChange={handleScoresChange}
                        onClose={handleRemoveChart}
                        onBan={handleBanChart}
                        // onLock={handleLockChart}
                        onReroll={handleReroll}
                        onReplace={handleReplacechart}
                        previewLocked={previewLocked}
                        isSongRevealed={isLockedIn}
                    />
                ))}
                { isLockedIn &&
                  <div
                  className={`transition-transform duration-300 mt-32 ${
                    isLockedIn
                      ? 'animate-scaleUp'
                      : 'animate-scaleDown'
                  } col-span-3 font-bold text-2xl`}
                >
                  - Revealed -
                </div>
                }
            </div>
          </div>
          <div className="flex-col overflow-y-scroll flex bg-zinc-100 h-screen relative z-10 w-[32rem]">
            <h3 className="mt-4 font-bold text-3xl w-full text-center mb-1">Control Panel</h3>
            <h3 className="text-xs px-12 w-full text-center mb-6">{"OBS -> Add Source -> Browser -> Set URL to www.kumakult.com/tools/to"}</h3>
            <SongPickPanel visible={visiblePickPanel} setVisible={setVisiblePickPanel} onSongPick={onSongPick} />
            <div className={`px-4 font-shippori flex flex-col justify-center text-center items-center px-6 bg-transparent`}>
              
              
            <h3 className='font-bold text-xs mb-2'>Difficulty Range</h3>
            <div className={`flex mb-2 gap-4`}>
                <div className={`flex gap-1 items-center bg-white overflow-hidden border-2 border-black`}>
                    <DropdownInputField
                        items={["12.0", "13.0"]}
                        type="text"
                        placeholder="Min"
                        value={minDiff}
                        onChange={(e) => setMinDiff(e)}
                        className="px-4 m-1 text-md w-20 bg-white text-black text-center focus:outline-none"
                    />
                </div>

                <div className={`flex gap-1 items-center bg-white overflow-hidden border-2 border-black`}>
                    <DropdownInputField
                        items={["13.0", "14.9"]}
                        type="text"
                        placeholder="Max"
                        value={maxDiff}
                        onChange={(e) => setMaxDiff(e)}
                        className="px-4 m-1 text-md w-20 bg-white text-black text-center focus:outline-none"
                    />
                </div>
            </div>

            <h3 className='font-bold text-xs mb-2'>Total Cards to Draw</h3>
            <div className={`flex mb-8 gap-4`}>
              <div className={`flex gap-1 items-center bg-white overflow-hidden border-2 border-black`}>
                <DropdownInputField
                    items={["5", "6", "7"]}
                    type="text"
                    placeholder="Amt"
                    value={totalCardsToDraw}
                    onChange={(e) => setTotalCardsToDraw(e)}
                    className="px-4 m-1 w-20 bg-white text-black text-center focus:outline-none"
                />
              </div>
            </div>

            <h3 className='font-bold text-xs mb-2'>Settings</h3>
            <div className="flex flex-wrap gap-2 mb-8 w-full justify-center">
              <button className={`text-2xs transition duration-200 px-4 py-2 whitespace-nowrap col-span-2 md:col-span-1 ${previewLocked ? "bg-red-400 hover:bg-red-300" : "bg-green-400 hover:bg-green-300"}`} onClick={() => setPreviewLocked(!previewLocked)}>
                  {previewLocked ? "Unlock Preview" : "Lock Preview"}
              </button>
              <button className="text-2xs transition duration-200 px-4 py-2 bg-pink-400 hover:bg-pink-300 whitespace-nowrap col-span-2 md:col-span-1" onClick={() => {
                switch(scoringMode){
                  case 0:
                    setScoringMode(1);
                    break;
                  case 1:
                    setScoringMode(2);
                    break;
                  case 2:
                    setScoringMode(0);
                    break;
                }
                handleScoresChange();
              }}>
                {scoringMode == 0 ? "Scoring: Wins" : (scoringMode == 1 ? "Scoring: Acc" : "Scoring: Gauntlet")}
              </button>
              <button className="text-2xs transition duration-200 px-4 py-2 bg-pink-400 hover:bg-pink-300 whitespace-nowrap col-span-2 md:col-span-1" onClick={() => {
                switch(versionMode){
                  case 0:
                    setVersionMode(1);
                    break;
                  case 1:
                    setVersionMode(0);
                    break;
                }
              }}>
                {versionMode == 0 ? "Version: Buddies" : "Version: Buddies Plus"}
              </button>
              <button className="text-2xs transition duration-200 px-4 py-2 bg-pink-400 hover:bg-pink-300 whitespace-nowrap col-span-2 md:col-span-1" onClick={() => {
                switch(lockedMode){
                  case 0:
                    setLockedMode(1);
                    break;
                  case 1:
                    setLockedMode(0);
                    break;
                }
              }}>
                {lockedMode == 0 ? "Locked Songs: No " : "Locked Songs: Yes"}
              </button>
            </div>


            <div className="flex flex-wrap gap-2 mb-8 w-full justify-center">
              <button className="text-2xs transition duration-200 px-4 py-2 bg-sky-300 hover:bg-sky-200 whitespace-nowrap" onClick={() => setVisiblePickPanel(true)}>
                  Select Specific Chart
              </button>
              <button className="text-2xs transition duration-200 px-4 py-2 bg-cyan-200 hover:bg-cyan-100 whitespace-nowrap col-span-2" onClick={() => randomize(totalCardsToDraw)}>
                  Draw [{totalCardsToDraw}] cards
              </button>
              <button 
                  className="text-2xs transition duration-200 px-4 py-2 bg-yellow-400 hover:bg-yellow-300 whitespace-nowrap col-span-2 md:col-span-1 w-full"
                  onClick={() => handleLockIn(!isLockedIn)}
              >
                { isLockedIn ? "Unreveal" : "Reveal" }
              </button>
              <button className="text-2xs transition duration-200 px-4 py-2 bg-red-400 hover:bg-red-300 whitespace-nowrap col-span-2 md:col-span-1 w-full" onClick={clear}>
                  Clear
              </button>
            </div>
          </div> 
          <h3 className='font-bold text-xs mb-2 font-shippori text-center'>Current Round</h3>
          <div className={`grid grid-cols-1 mb-4 gap-1 w-full font-shippori px-4`}>
            <DropdownInputField
              items={["Top 4", "Loser's Finals", "Grand Finals", "Elimintion", "Losers' Elimation", "Losers' Top 4"]}
              ref={roundTextRef}
              type="text"
              placeholder="Round Text"
              className="pr-2 border-black border-2 py-1 w-full text-xs text-black text-center  bg-transparent focus:outline-none"
            />
            <button className="text-2xs transition duration-200 px-4 py-2 bg-yellow-400 hover:bg-yellow-300 whitespace-nowrap col-span-3 w-full" onClick={() => {setRoundLabel(roundTextRef.current.value)}}>
              Submit
            </button>
          </div>
          <h3 className='font-bold text-xs mb-2 font-shippori text-center'>Teams</h3>
          <div className={`grid grid-cols-2 mb-4 gap-1 w-full font-shippori px-4`}>
            <DropdownInputField
              items={teams}
              ref={p1UserRef}
              type="text"
              placeholder="P1"
              className="pr-2 border-black border-2 py-1 w-full text-xs text-black text-center  bg-transparent focus:outline-none"
            />
            <DropdownInputField
              items={teams}
              ref={p2UserRef}
              type="text"
              placeholder="P2"
              className="pr-2 border-black border-2 py-1 w-full text-xs text-black text-center  bg-transparent focus:outline-none"
            />
            <DropdownInputField
              items={teams}
              ref={p3UserRef}
              type="text"
              placeholder="P3"
              className="pr-2 border-black border-2 py-1 w-full text-xs text-black text-center  bg-transparent focus:outline-none"
            />
            <DropdownInputField
              items={teams}
              ref={p4UserRef}
              type="text"
              placeholder="P4"
              className="pr-2 border-black border-2 py-1 w-full text-xs text-black text-center  bg-transparent focus:outline-none"
            />
            <button className="text-2xs transition duration-200 px-4 py-2 bg-yellow-400 hover:bg-yellow-300 whitespace-nowrap col-span-2 w-full" onClick={onSubmitPlayers}>
              Submit
            </button>
          </div>
          <h3 className='font-bold text-xs mb-2 font-shippori text-center'>Commentators</h3>
          <div className={`grid grid-cols-3 mb-4 gap-1 w-full font-shippori px-4`}>
            <DropdownInputField
              items={players}
              ref={p1commentatorRef}
              type="text"
              placeholder="P1"
              className="pr-2 border-black border-2 py-1 w-full text-xs text-black text-center  bg-transparent focus:outline-none"
            />
            <DropdownInputField
              items={players}
              ref={p2commentatorRef}
              type="text"
              placeholder="P2"
              className="pr-2 border-black border-2 py-1 w-full text-xs text-black text-center  bg-transparent focus:outline-none"
            />
            <DropdownInputField
              items={players}
              ref={p3commentatorRef}
              type="text"
              placeholder="P3"
              className="pr-2 border-black border-2 py-1 w-full text-xs text-black text-center  bg-transparent focus:outline-none"
            />
            <button className="text-2xs transition duration-200 px-4 py-2 bg-yellow-400 hover:bg-yellow-300 whitespace-nowrap col-span-3 w-full" onClick={onSubmitCommentators}>
              Submit
            </button>
          </div>

          <h3 className='font-bold text-xs mb-2 font-shippori text-center'>Players List</h3>
          <div className={`w-full font-shippori px-4`}>
            <textarea
              className="w-full border-black border-2 resize-none text-xs font-shippori px-2 pb-40 pt-2 mb-4"
              onChange={onPlayersListChange}
            />
          </div>

          <h3 className='font-bold text-xs mb-2 font-shippori text-center'>Teams List</h3>
          <div className={`w-full font-shippori px-4`}>
            <textarea
              className="w-full border-black border-2 resize-none text-xs font-shippori px-2 pb-40 pt-2 mb-4"
              onChange={onTeamsListChange}
            />
          </div>
      


          <h3 className='font-bold text-xs mb-4 font-shippori text-center'>Abilities</h3>
          <div className={`grid grid-cols-3 mb-4 gap-5 w-full font-shippori px-4`}>
            {abilities.map((item) => (
                <CrewAbilityCard key={item.title} item={item} showDescription={false}
                onClick={onCrewClick}/>
            ))}
          </div>

          <h3 className='font-bold text-xs mb-4 font-shippori text-center'>Songs</h3>
          <div className={`grid grid-cols-1 mb-4 gap-5 w-full font-shippori px-4`}>
            {/* <div className="grid grid-cols-2 gap-0.5">
              <h3 className='font-bold text-xs font-shippori text-center bg-purple-400 col-span-2 py-1'>ハッピーシンセサイザ</h3>
              <button className="bg-red-400 text-2xs py-1 py-1">Remove</button>
              <button className="bg-red-400 text-2xs py-1 py-1">Ban</button>
              <button className="bg-red-400 text-2xs py-1 py-1">Lock</button>
              <button className="bg-red-400 text-2xs py-1 py-1">Replace</button>
            </div> */}
            {randomizedCharts.map((chart, index) => (
              <CrewSongCard
                items={players}
                key={`${chart.id}-${chart["Difficulty Id"]}`}
                song={chart}
                animate={false}
                p1User={p1User}
                p2User={p2User}
                p3User={p3User}
                p4User={p4User}
                onP1UserChange={handleP1UserChange}
                onP2UserChange={handleP2UserChange}
                onScoresChange={handleScoresChange}
                onClose={handleRemoveChart}
                onBan={handleBanChart}
                // onLock={handleLockChart}
                onReroll={handleReroll}
                onReplace={handleReplacechart}
                isControlPanel={true}
                isSongRevealed={isLockedIn}
              />
            ))}
          </div>
        </div>
      </main>
    );
};

export default CrewOrganizerPage;