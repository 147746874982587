import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';

const DropdownInputField = forwardRef(({ items, className, placeholder, value, onChange }, ref) => {
  const [filteredItems, setFilteredItems] = useState([]);
  const [text, setText] = useState(value);
  const itemsInputRef = useRef(null);

  useImperativeHandle(ref, () => ({
    value: text, // Expose the text value to the parent
  }));

  useEffect(
    () => {
      setText(value);
    }, [value]);

  const handleInputChange = (e) => {
    const value = e.target.value;

    if (value) {
      const filtered = items.filter(item =>
        item.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredItems(filtered);
    } else {
      setFilteredItems([]);
    }

    if (onChange) {
      onChange(value);
    }

    setText(value);
  };

  const handleItemSelect = (item) => {
    setText(item);

    if (onChange) {
      onChange(item);
    }

    setFilteredItems([]);
  };

  return (
    <div className="relative">
      <input
        ref={itemsInputRef}
        type="text"
        value={text}
        onChange={handleInputChange}
        placeholder={placeholder}
        className={className}
      />
      {filteredItems.length > 0 && (
        <ul className="absolute w-full z-20 bg-white border-2 border-zinc-500 overflow-auto flex flex-col text-xs font-shippori text-zinc-800 py-1 px-1 mt-1">
          {filteredItems.map((item, index) => (
            <li
              key={index}
              className="px-1 cursor-pointer hover:bg-yellow-300"
              onClick={() => handleItemSelect(item)}
            >
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
});

export default DropdownInputField;
