import React, { useEffect, useRef, useState } from 'react';
import Mascot from '../../Components/Mascot';
import SongCard from '../../Components/SongCard';
import BasicCard from '../../Components/BasicCard';
import TeamViewer from '../../Components/Tournament/TeamViewer';
import Charts from '../../Data/Charts.json';
import CrewAbilities from '../../Data/CrewAbilities.json';
import axios from 'axios';
import { FaStar } from "react-icons/fa";

const CrewBattlePage = () => {
    const requestAbortController = React.useRef(null);
    const [crews, setCrews] = React.useState([]);
    const [fadeIn, setFadeIn] = useState(false);
    const filteredCharts = Charts.filter(song => song.Title === "[協]疾走あんさんぶる" );

    const teams = [
        {
            characters: [
                {
                    image: "./../art/characters/char-3.png", 
                    rating: 16200, 
                    power: 4,
                    captain: true,
                },
                {
                    image: "./../art/characters/char-4.png", 
                    rating: 14700, 
                    power: 1,
                    captain: false,
                },
                {
                    image: "./../art/characters/char-5.png", 
                    rating: 13900, 
                    power: 1,
                    captain: false,
                },
            ],
            total: 6,
            handicap: false,
        },
        {
            characters: [
                {
                    image: "./../art/characters/char-2.png", 
                    rating: 15900, 
                    power: 3,
                    captain: true,
                },
                {
                    image: "./../art/characters/char-0.png", 
                    rating: 15800, 
                    power: 3,
                    captain: false,
                },
                {
                    image: "./../art/characters/char-1.png", 
                    rating: 13900, 
                    power: 1,
                    captain: false,
                },
            ],
            total: 7,
            handicap: false,
        },
        {
            characters: [
                {
                    image: "./../art/characters/char-8.png", 
                    rating: 15220, 
                    power: 2,
                    captain: true,
                },
                {
                    image: "./../art/characters/char-7.png", 
                    rating: 15550, 
                    power: 2,
                    captain: false,
                },
                {
                    image: "./../art/characters/char-6.png", 
                    rating: 14100, 
                    power: 1,
                    captain: false,
                },
            ],
            total: 5,
            handicap: true,
        },
    ]

    const fetchTeams = async () => {
        const controller = new AbortController();
    
        try {
            const response = await axios.get(
                'https://sheets.googleapis.com/v4/spreadsheets/1_4F7w7-2CZg7dc8xAjul6KHa_t9rvKNrwW5pwWDxrP4/values/data!A2:F?key=AIzaSyASPeN82JnlKM49W6pJPTvxLisjc8b9X3w'
            );
    
            let loadedTeams = [];
            response.data.values.forEach(row => {
                let [date, teamName, captain, player2, player3, powerRating] = row;
                loadedTeams.push({date: date, teamName: teamName, captain: captain, player2: player2, player3: player3, powerRating: powerRating});
            });
    
            return loadedTeams;
        }   catch (error) {
            console.error('Error fetching data from Google Sheets:', error);
            return [];
        }
    
        requestAbortController.current = controller;
    };

    const updateTeams = async () => {
        let loadedTeams = await fetchTeams();
        setCrews(loadedTeams);
    }

    useEffect(() => { 
        setFadeIn(true);
        window.scrollTo(0, 0);
        updateTeams();
    }, []);

    return (
        <main className="font-shippori bg-white">
            <div className={`max-w-6xl mx-auto py-16 items-center flex-col flex min-h-[calc(80vh)] transition-opacity duration-5000 ${fadeIn ? 'opacity-100' : 'opacity-0'}`}>
                <h1 className="text-4xl mb-2 mx-4">maimai CREW BATTLE</h1>
                <h2 className="text-sm font-bold text-zinc-600 mb-12 mx-4">a team-based tournament with strategic components!</h2>
                <h1 className="text-xl text-black font-bold animate-bounce mb-24">
                    <Mascot />
                </h1>
                <div className="mx-4">
                    <h3 className="text-md mb-2 font-bold mb-8 w-full bg-black py-1 text-white px-2">Registration</h3>
                    <h4 className="text-xl mb-4 font-bold">Information</h4>
                    <p className="mb-8">The event will take place at kumakult on Nov 2, Saturday. Fill-in and any additional teams may be formed at Kumakult on day of event!</p>
                    <button className={`px-8 py-4 mb-8 text-lg transition duration-200 animate-pulse bg-yellow-400 hover:bg-yellow-300 transition`}><a href="https://forms.gle/Lt5SpbU3UvwjuLKf6">Register Team!</a></button>

                    <h4 className="text-xl mb-4 font-bold">Current Teams</h4>
                    <div className="grid grid-cols-2 md:grid-cols-3 mb-8 gap-4">
                        {crews.map((crew, index) => (
                            <div key={index} className="border-2 border-zinc-700 rounded-b-lg pb-1">
                                <div className="bg-zinc-700 font-bold text-white px-2 py-0.5 text-sm flex justify-between">
                                    <div>{crew.teamName}</div>
                                    <div>{crew.powerRating}</div>
                                </div>
                                <div className="flex flex-col pt-1">
                                    <p className="px-2 flex gap-1 items-center"><FaStar /> {crew.captain}</p>
                                    <p className="px-2">{crew.player2}</p>
                                    <p className="px-2">{crew.player3}</p>
                                </div>
                            </div>
                        ))}
                    </div>


                    <h3 className="text-md mb-2 font-bold mb-8 w-full bg-black py-1 text-white px-2">Rules</h3>
                    <div className="mb-12">
                    <h4 className="text-xl mb-4 font-bold">Team Structure</h4>
                        <div className="w-full mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
                            <div>
                                <h4 className="mb-2 font-bold">Composition</h4>
                                <ul className="list-disc mb-6 ml-4">
                                    <li>Each team consists of <span className="bg-yellow-500 text-white font-bold text-sm rounded-lg px-2">3 people</span></li>
                                    <li>Power Rating cannot exceed <span className="bg-rose-500 text-white font-bold text-sm rounded-lg px-2">7 points</span> per team (see below)</li>
                                    <li>A Handicap will be given to teams of 5 or less points</li>
                                    <li>A <span className="bg-cyan-500 text-white font-bold text-sm rounded-lg px-2">Team Captain</span> and Team Name has to be assigned to every team</li>
                                </ul>
                                <h4 className="mb-2 font-bold">Power Rating</h4>
                                <ul className="list-disc ml-4 mb-6">
                                    <li>16000 rating or more <span className="bg-rose-500 text-white font-bold text-sm rounded-lg px-2">4 points</span></li>
                                    <li>15600-15999 rating <span className="bg-orange-500 text-white font-bold text-sm rounded-lg px-2">3 points</span></li>
                                    <li>14800-15599 rating <span className="bg-yellow-500 text-white font-bold text-sm rounded-lg px-2">2 points</span></li>
                                    <li>less than 14800 rating <span className="bg-green-500 text-white font-bold text-sm rounded-lg px-2">1 point</span></li>
                                </ul>
                                <h4 className="mb-2 font-bold">Fill</h4>
                                <ul className="list-disc mb-6 ml-4">
                                    <li>If you want to fill or if your team needs a fill, arrangements can be made at kumakult (day of event) or on Discord.</li>
                                </ul>
                            </div>
                            <TeamViewer teams={teams} />
                        </div>
                    </div>
                    <h4 className="text-xl mb-4 font-bold">Rounds</h4>
                    <ul className="list-decimal ml-4 mb-8">
                        <li>At the start of each round, 5 different random categories will be drawn. They can be power, tech, stamina, intelligence, trills, or spins.
                            <ul className="list-disc ml-4">
                                <li>The songs will be partially revealed like this: <span className="bg-zinc-500 text-white font-bold text-sm rounded-lg px-2">Power 13</span>, <span className="bg-zinc-500 text-white font-bold text-sm rounded-lg px-2">Tech 14</span>, <span className="bg-zinc-500 text-white font-bold text-sm rounded-lg px-2">Intelligence 13</span>, <span className="bg-zinc-500 text-white font-bold text-sm rounded-lg px-2">Trills 12</span>, <span className="bg-zinc-500 text-white font-bold text-sm rounded-lg px-2">Tech 12+</span></li>
                                <li>Difficulty will range from <span className="bg-zinc-500 text-white font-bold text-sm rounded-lg px-2">12.0 - 14.6</span>. During top 4, the difficulty range will change to <span className="bg-zinc-500 text-white font-bold text-sm rounded-lg px-2">13.0 - 14.9</span></li>
                            </ul>
                        </li>
                        <li>After cateogires are drawn, the lower seed team will ban a card first. The higher seed will follow until there are 3 categories remaining.</li>
                        <li>The Team Captains will nominate who to play for each category. Upper seed team picks first, followed by the lower seed team.</li>
                        <li>After players are nominated, songs will be <span className="bg-yellow-500 text-white font-bold text-sm rounded-lg px-2">Fully Revealed</span>. Example: <span className="bg-zinc-500 text-white font-bold text-sm rounded-lg px-2">Intelligence 13</span> {"-->"} <span className="bg-purple-500 text-white font-bold text-sm rounded-lg px-2">Umiyuri Master 13</span></li>
                        <li>After songs are revealed, upper seed team will choose the team to go first. The first team will use their <span className="bg-green-500 text-white font-bold text-sm rounded-lg px-2">Team Ability</span> first, and following that, the other team will use abilitie(s) that were not used by the other team.</li>
                        <ul className="list-disc ml-4">
                            <li>If a team has a handicap, that team will burn two abilities in one go.</li>
                        </ul>
                        <li>The players will then play the songs!</li>
                        <ul className="list-disc ml-4">
                            <li>The tournament's scoring is determined by <span className="bg-yellow-500 text-white font-bold text-sm rounded-lg px-2">Accuracy</span> achieved per song.</li>
                            <li>If the accuracy difference per song is greater than <span className="bg-zinc-500 text-white font-bold text-sm rounded-lg px-2">1.000%</span>, the losing team's accuracy will be rounded up so the difference is 1.000% <span className="bg-red-500 text-white font-bold text-sm rounded-lg px-2">UNLESS</span> if its the <span className="bg-yellow-500 text-white font-bold text-sm rounded-lg px-2">Last Song</span>.</li>
                        </ul>
                    </ul>
                    <h4 className="text-xl mb-4 font-bold">Tournament Format</h4>
                    <p className="mb-12">The bracket will be a double-elimination with a bracket reset in the grand finals.</p>
                    <h4 className="text-xl mb-4 font-bold text-center">Team Abilities</h4>
                    <div className="grid grid-cols-2 lg:grid-cols-4 mb-12 gap-2 lg:gap-4">
                    {CrewAbilities.map((item) => (
                        <BasicCard key={item.title} item={item}/>
                    ))}
                    </div>
                    <h4 className="text-xl mb-4 font-bold">Seeding</h4>
                    <p className="mb-12">Each team will play Dashou Ensemble Utage (3-player) on a team member's account. The seeding will be done in-person and teams will be seeded by score. A higher seed rating will give you a competitive advantage!</p>
                    <div className="justify-center place-items-center place-content-center grid grid-cols-1 mb-12">
                        {filteredCharts.map((chart) => (
                            <SongCard key={chart.id} song={chart} />
                        ))}
                    </div>
                </div>
            </div>
        </main>
    );
};

export default CrewBattlePage;