import React, { useState, useRef, useEffect } from 'react';

const CrewAbilityCard = ({ item, onClick, previewLocked, showDescription }) => {
    const info = item.description?.split('`');
    const highlightColors = ['bg-yellow-300', 'bg-cyan-300', 'bg-rose-400'];

    const [isUsed, setIsUsed] = useState(item.isUsed);
    useEffect(() => {
        setIsUsed(item.isUsed);
    }, [ item.isUsed ]);

    return (
        <div 
            className={`relative w-full font-shippori select-none ${isUsed && 'opacity-20'}`}
            style={{ pointerEvents: previewLocked ? 'none' : 'auto' }}
            onClick={() => {
                setIsUsed(!isUsed);
                onClick(item)
            }}
        >
            <div className={`bg-white rounded-lg overflow-hidden flex gap-4 ${!item.done && 'border-8 ring-2 ring-black border-white'}`}>
                <div>
                    {item.image && <img src={item.image} className="max-w-24 h-28 object-cover" alt={item.title} />}
                    <div className="bg-white pt-1.5 flex justify-between items-center">
                        {item.title && <h3 className="text-black font-bold text-2xs">{item.title}</h3>}
                    </div>
                </div>
                <div className="flex">
                    { showDescription && 
                        <div>
                            {info.map((text, index) =>
                                index % 2 === 0
                                    ? <span key={index} className="text-black"> {text} </span>
                                    : <span key={index} className={`${highlightColors[(Math.floor(index / 2)) % 3]} text-xs font-bold text-black px-1 py-0.5 whitespace-nowrap inline-block`}> {text} </span>
                            )}
                        </div>
                    }
                    </div>
            </div>
        </div>
    );
}

export default CrewAbilityCard;
