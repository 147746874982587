import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../Auth/AuthContext';
import { useNavigate } from 'react-router-dom';
import SecretSanta from '../../Data/SecretSanta.json';

const getSecretSantaPairings = (participants, seed, userId) => {
    // Check if userId exists in the list
    const userExists = participants.some(participant => participant.Id === userId);
    if (!userExists) return null;

    const shuffleArray = (array, seed) => {
        const arr = [...array];
        let m = arr.length;
        let t, i;

        while (m) {
            i = Math.floor(random(seed) * m--);
            t = arr[m];
            arr[m] = arr[i];
            arr[i] = t;
        }

        return arr;
    };

    // Simple PRNG (Pseudo-Random Number Generator) using the seed
    const random = (seed) => {
        const x = Math.sin(seed++) * 10000;
        return x - Math.floor(x);
    };

    // Extract participant ids and aliases
    const ids = participants.map(p => p.Id);
    const aliases = participants.reduce((acc, p) => {
        acc[p.Id] = p.alias;
        return acc;
    }, {});

    // Create the Secret Santa pairings (last person gives to the first)
    const shuffledIds = shuffleArray(ids, seed);
    const pairings = {};
    for (let i = 0; i < shuffledIds.length; i++) {
        const giver = shuffledIds[i];
        const receiver = shuffledIds[(i + 1) % shuffledIds.length];
        pairings[giver] = receiver;
    }

    // Find the giftee for the user
    const gifteeId = pairings[userId];
    const gifteeAlias = aliases[gifteeId];
    const gifterId = Object.keys(pairings).find(giver => pairings[giver] === userId);
    const gifterAlias = aliases[gifterId];

    return {
        giftee: { alias: gifteeAlias, id: gifteeId },
        gifter: { alias: gifterAlias, id: gifterId },
        pairings: pairings,
        aliases: aliases
    };
};

const SecretSantaPage = () => {
    const { user } = useAuth();
    // const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            return (
                <main className="max-w-xl mx-auto py-24 px-4 font-shippori flex flex-col justify-center text-center items-center px-6">
                    <h1 className="flex gap-2 text-2xl">Please log in.</h1>
                </main>
            );
        }
    }, [user]);

    if (!user) {
        return (
            <main className="max-w-xl mx-auto py-24 px-4 font-shippori flex flex-col justify-center text-center items-center px-6">
                <h1 className="flex gap-2 text-2xl">Please log in.</h1>
            </main>
        );
    }

    const seed = 24;
    const secretSanta = getSecretSantaPairings(SecretSanta, seed, user.id);

    if (secretSanta === null) {
        return (
            <main className="max-w-xl mx-auto py-24 px-4 font-shippori flex flex-col justify-center text-center items-center px-6">
                <h1 className="flex gap-2 text-2xl">You are not in Secret Santa.</h1>
            </main>
        );
    }

    return (
        <main className="max-w-xl mx-auto py-24 px-4 font-shippori flex flex-col justify-center text-center items-center px-6">
            <h1 className="text-black text-4xl font-bold">Secret Santa</h1>
            <h2 className="text-black text-sm mb-24">who are you gifting? Jan 11 @ kumakult ($30-$40)</h2>
            <div className="bg-zinc-200 border-4 border-black rounded-lg px-12 pt-6 pb-8">
                <p>You are gifting: {secretSanta.giftee.alias}</p>
                <p className="text-xs">Do not let them know!</p>
            </div>
            {/* <p className="mb-20">{secretSanta.gifter.id} is gifting you.</p> */}

            {/* <div className="mt-20">
                <p className="mb-6 bg-yellow-300 text-3xl py-2">TEST</p>
                {
                    Object.keys(secretSanta.pairings).map((id, index) => {
                        const gifter = secretSanta.aliases[id].substring(0, 20);
                        const giftee = secretSanta.aliases[secretSanta.pairings[id]].substring(0, 30);
                        return (
                            <div key={index}>
                                <span className="bg-cyan-300">{gifter}</span> is giving <span className="bg-rose-300">{giftee}</span>
                            </div>
                        )
                    }
                    )}
            </div> */}
        </main>
    );
};

export default SecretSantaPage;
