import React, { useState, useMemo, useEffect, useRef } from 'react';
import { FaWindowClose } from "react-icons/fa";
import Charts from './../../Data/Charts';
import SongCard from '../SongCard';

const PickPanel = ({ visible, setVisible, onSongPick }) => {
    const [filterText, setFilterText] = useState("");
    const [debouncedText, setDebouncedText] = useState(filterText);
    const debounceTimeoutRef = useRef(null);
    const searchBar = useRef(null);

    useEffect(() => {
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }

        debounceTimeoutRef.current = setTimeout(() => {
            setDebouncedText(filterText);
        }, 150);

        return () => {
            clearTimeout(debounceTimeoutRef.current);
        };
    }, [filterText]);

    useEffect(() => {
        if (visible && searchBar.current) {
            searchBar.current.focus();
        }
    }, [visible]);

    const filteredCharts = useMemo(() => {
        return Charts.filter(chart =>
            chart["Search Tags"].toLowerCase().includes(debouncedText.toLowerCase())
        ).slice(0, 50);
    }, [debouncedText]);

    const handleFilterTextChange = (e) => {
        setFilterText(e.target.value);
    };

    const onSongClick = (song) => {
        setVisible(false);
        onSongPick(song);
    };

    if (!visible) return null;

    return (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-80 z-20">
            <div className="absolute bg-white w-full px-2 transform -translate-x-1/2 left-1/2">
                <button
                    onClick={() => { setVisible(false); }}
                    className="text-black hover:text-red-500 text-xl rounded-lg absolute right-2 top-2"
                >
                    <FaWindowClose />
                </button>
                <h2 className="text-2xl text-center mt-6">Chart Selection</h2>
                <h3 className="text-xs font-bold text-center mb-12">Pick a specific chart</h3>
                <div className="w-full flex gap-2 px-4 mb-2">
                    <input
                        type="text"
                        placeholder="Search"
                        value={filterText}
                        onChange={handleFilterTextChange}
                        ref={searchBar}
                        className="px-1 py-0.5 text-sm w-full rounded-lg bg-zinc-100 px-2 py-0.5"
                    />
                    <button
                        onClick={() => { setFilterText(''); }}
                        className="bg-cyan-300 hover:bg-cyan-200 px-4 text-xs rounded-lg"
                    >
                        Clear
                    </button>
                </div>
                <div className="grid grid-cols-2 overflow-y-scroll h-96 px-4 gap-1 mb-4 content-start">
                    {filteredCharts.map((chart, index) => (
                        <SongCard key={index} song={chart} small={true} onClick={onSongClick} />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default PickPanel;
